.blackColor {
	background: #444;
}

.container {
	width: 1400px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
}

.navbar {
	padding: .6rem 0;
}

.navbar-brand img {
	width: 10rem;
}

ul.navbar-nav {
	display: flex;
	list-style: none;
}

ul.navbar-nav li a {
	font-weight: 700;
	margin: 0 1rem;
	color: #d91921;
	font-size: .9rem;
}

.h-100 {
	height: 100% !important;
}

.card {
	word-wrap: break-word;
	background-clip: border-box;
	border-radius: .2rem;
	color: #1e1e1e;
	display: flex;
	flex-direction: column;
	min-width: 0;
	position: relative;
}

.poster {
	display: block;
	min-height: 100%;
	overflow: hidden;
	position: relative;
}

.rounded {
	border-radius: .2rem !important;
}

.list-movie .poster::before {
	content: url(https://ww2.123moviesfree.net/img/play.svg);
	height: auto;
	left: calc(50% - 30px);
	opacity: 0;
	position: absolute;
	top: calc(50% - 30px);
	width: 70px;
	z-index: 1000;
}

.poster:hover::after,
.poster:hover::before {
	opacity: 1;
	transition: all .2s ease-in-out;
}
.headButton{
	text-align:end;
}
.shadow {
	box-shadow: 0 0 5px rgba(0, 0, 0, .3) !important;
}

.item-title {
	background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .65) 28%, rgba(0, 0, 0, .65));
	color: #fff;
	font-size: .8rem;
	bottom: 0;
	left: 0;
	text-align: center;
	width: 100%;
	z-index: 1;
	height: auto;
	position: absolute;
}

.preloader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
}


@media (max-width: 1400px) {
	.container {
		width: 1120px;
	}
}

@media (max-width: 1160px) {
	.container {
		width: 1120px;
	}
}

@media (max-width: 1160px) {
	.container {
		width: 960px;
	}
}

@media (max-width: 992px) {
	.container {
		width: 720px;
		margin: .6rem;
	}
}

@media (max-width: 768px) {
	.container {
		width: 540px;
		margin: .6rem;
	}
}

@media (max-width: 578px) {
	.container {
		width: 94%;
		margin: .6rem;
	}
	.navbar-brand img {
		width: 100%;
	}
}